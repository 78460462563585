import React, { useEffect } from 'react';
import '@src/styles/_term.scss';

const PrivacyTerms = () => {
  useEffect(() => {
    document.title = '포미포인트 이용약관';
  }, []);

  return (
    <div className="term-wrapper">
      <h1 className="title">포미포인트 이용약관</h1>
      <h2 className="subtitle">제1조 (목적) </h2>
      <p>
        본 약관은 주식회사 위메프오(이하 “회사”라 합니다.)가 운영하는 포미포인트 서비스의 이용과 관련하여 “회사”와
        “회원”의 권리·의무 및 책임사항을 규정함을 그 목적으로 합니다.
      </p>
      <h2 className="subtitle">제2조 (용어의 정의) </h2>
      <ul className="numberList">
        <li>
          “포미포인트”란, 해당 서비스에 가입한 “회원”이 “제휴사”의 매장 내 상품을 구매함에 따라 해당 “제휴사”로부터
          “포인트”를 제공받고, 향후 “회원”이 해당 매장에서 “포인트”를 이용하여 상품 등과 교환할 수 있도록 “회사”가
          제공하는 “모바일 어플리케이션” 기반의 서비스 및 관련 부가서비스를 의미하며, 이에 한정되지 않고 추후 “회사”에서
          공지하고 제공하는 기타 웹사이트 및 서비스 앱을 포함합니다.
        </li>
        <li>
          “회원”이란, “포미포인트”에 접속하여 이 약관에 동의하고 가입함에 따라 “회사”가 제공하는 “포미포인트 서비스”를
          이용하는 “포미포인트”의 회원을 의미합니다.
        </li>
        <li>“포미포인트 서비스”란 “회사”가 “포미포인트”를 통해 “회원”에게 제공하는 모든 서비스를 의미합니다. </li>
        <li>
          “제휴사”란, “포인트”에 관한 정보를 “포미포인트”에 게재하고 “회원”에게 제공 및 “회사”가 제공하는 “포미포인트
          서비스”를 이용하는 사업자를 의미합니다.
        </li>
        <li>“매장”이란, “제휴사”가 운영 및 관리하고 있는 전체 지점(직영점 및 가맹점 모두 포함)을 의미합니다. </li>
        <li>
          “포인트”란, “회원”이 상품 등을 구매할 경우 “제휴사”가 제공하는 적립금으로, “회원”은 적립한 “포인트”를 이용하여
          해당 매장에서 “회사”와 “제휴사”가 사전 협의한 범위 내의 상품 등을 구매할 수 있습니다.
        </li>
        <li>
          “모바일 어플리케이션”이란, “회사”가 “회원”에게 “포미포인트 서비스”를 제공하기 위하여 관리 및 운영하는 모든
          어플리케이션(App)을 의미하며, “회원”은 “모바일 어플리케이션”을 통하여 포인트를 제공받을 수 있으며, 적립 포인트
          정보 등을 확인할 수 있으며, “매장”에서 포인트를 사용할 수 있습니다.
        </li>
      </ul>
      <h2 className="subtitle">제3조 (약관의 명시 및 개정) </h2>
      <ul className="numberList">
        <li>
          “회사”는 이 약관의 내용과 상호 및 영업소 소재지 주소, 대표자의 설명, 사업자등록번호, 연락처(전화, 전자우편
          주소 등) 등을 “회원”이 쉽게 확인할 수 있도록 “포미포인트” 어플리케이션의 ‘휴대폰번호 입력영역’ - ‘이용약관’
          화면 및 “포미포인트” 적립 안내 ‘알림톡’ 또는 ‘MMS’ 메시지 - ‘이용약관’ URL내용에 게시합니다.
        </li>
        <li>
          “회사”는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제 등에 관한 법률』, 『전자문서 및
          전자거래기본법』, 『전자서명법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관련법을
          위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </li>
        <li>
          “회사”가 약관을 개정할 경우에는 개정 약관의 적용일자 7일 이전부터 적용일자 전일까지 적용일자 및 개정사유를
          명시하여 현행 약관과 함께 “포미포인트” 어플리케이션의 ‘휴대폰번호 입력영역’ - ‘이용약관’ 화면 및 “포미포인트”
          적립 안내 ‘알림톡’ 또는 ‘MMS’ 메시지 - ‘이용약관’ URL내용에 공지합니다. 다만, 약관 내용을 “회원”에게 불리하게
          변경하는 경우에는 최소 30일 이상의 사전 유예시간을 두고 공지합니다. 이 경우 “회사”는 개정 전과 개정 후 내용을
          명확하게 비교하여 “회원”이 알기 쉽도록 표시합니다.
        </li>
        <li>
          “회원”은 개정된 약관에 동의하지 않을 권리가 있으며, 이 경우에는 “포미포인트 서비스”의 이용을 중단하고 탈퇴를
          신청할 수 있습니다. 다만, “회원”이 개정 약관의 적용일 전일까지 “회사”에 명시적인 거부의 의사표시를 하지
          아니하는 경우에는 개정 약관에 동의하는 것으로 간주됩니다.
        </li>
        <li>
          “회사”는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건을 둘 수 있으며, 개별 서비스에서
          별도로 적용되는 약관에 대한 동의는 회원이 개별 서비스를 최초로 이용할 경우 별도의 동의절차를 거치게 됩니다. 이
          경우 개별 서비스에 대한 이용약관 등이 이 약관에 우선합니다.
        </li>
        <li>
          “회원”은 약관 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 “회원”의 피해는 “회사”가
          책임지지 않습니다.
        </li>
        <li>
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관한 사항은 『전자상거래 등에서의 소비자보호에 관한 법률』,
          『약관의 규제 등에 관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자보호지침』 및 관계 법령
          또는 상관례에 따릅니다.
        </li>
      </ul>
      <h2 className="subtitle">제4조 (포미포인트 서비스의 제공) </h2>
      <p>“회사”는 다음과 같은 업무를 수행합니다.</p>
      <ul className="numberList">
        <li>포인트 적립 및 이용 지원 서비스 </li>
        <li>제휴 매장 검색 및 이용 지원 서비스 </li>
        <li> 이에 수반되는 기타 서비스 일체 </li>
      </ul>

      <h2 className="subtitle">제5조 (포미포인트 서비스의 변경 및 중단) </h2>
      <ul className="numberList">
        <li>
          “회사”는 “포미포인트 서비스”의 제공과 관련한 “회사” 정책의 변경 등 운영상 또는 기술상의 필요에 따라 제공하고
          있는 “포미포인트 서비스”의 전부 또는 일부를 변경 또는 중단할 수 있습니다.
        </li>
        <li>
          “회사”는 “포미포인트 서비스”의 원활한 수행을 위하여 필요한 기간을 정하여 사전에 공지하여 “포미포인트 서비스”를
          중지할 수 있습니다. 단, 불가피하게 긴급한 조치를 필요로 하는 경우 사후에 통지할 수 있습니다.
        </li>
        <li>
          “회사”는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 “포미포인트
          서비스”의 제공을 일시적으로 중단할 수 있습니다.
        </li>
        <li>
          “회사”는 “포미포인트 서비스”의 내용, 이용시간, 이용방법에 대한 변경 또는 중단이 있을 경우 변경 또는 중단 전에
          “회원”이 충분히 인지할 수 있는 방법으로 사전에 고지합니다.
        </li>
      </ul>
      <h2 className="subtitle">제6조 (회원가입 및 이용계약의 성립) </h2>

      <ul className="numberList">
        <li>
          “회원”이 되고자 하는 자가 “회사”가 정한 절차에 따라 회원정보를 기입하고 이 약관에 대한 동의 의사를 표시하여
          회원가입을 신청하면, “회사”가 이를 승낙함으로써 이용계약이 체결됩니다.
        </li>
        <li>
          “회사”는 다음의 각 호에 해당하는 가입신청에 대해서는 회원가입 신청을 거부하거나 이용계약을 해지할 수 있습니다.
          <ul>
            <li> “회원” 본인의 명의가 아닌 타인의 명의를 도용하여 회원가입 또는 재가입을 신청하는 경우</li>
            <li>회원정보에 사실과 다른 정보를 기재하거나 누락하는 경우</li>
            <li>
              이 약관에 의하여 “회원” 자격을 상실한 이력이 있는 경우. 단, “회사”가 이를 알고도 재가입을 승낙한 경우에는
              예외로 함.
            </li>
            <li>
              기타 회원가입 신청자를 “회원”으로 등록하는 것이 기술상 불가능하거나, 이 약관에 위배되거나 위법 또는 부당한
              신청임이 확인되는 경우 또는 “회사”가 합리적인 판단에 의하여 필요하다고 인정하는 경우
            </li>
          </ul>
        </li>
        <li>
          “회사”는 “회원”이 회원정보를 변경하고자 하는 경우 “회사”가 정한 본인 확인 절차 등을 거치도록 할 수 있습니다.
        </li>
        <li>
          “회사”가 신청절차 상 가입완료 의사를 표시하였을 때 “회사”와 “회원”간 이 약관의 내용에 따른 이용계약이 성립한
          것으로 봅니다.
        </li>
        <li>
          “회원”은 “회사”에 등록한 회원정보에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 등의 방법으로 “회사”에
          그 변경사항을 알려야 하며, 회원정보를 수정하지 않음으로써 발생하는 “회원”의 손해에 대하여 “회사”는 아무런
          책임을 지지 아니합니다.
        </li>
      </ul>
      <h2 className="subtitle">제7조 (회원탈퇴 및 이용계약의 종료) </h2>
      <ul className="numberList">
        <li>
          “회원”은 “회사”가 정한 절차에 따라 언제든지 “포미포인트”의 탈퇴를 신청할 수 있으며, 이 경우 “회사”는 “회원”의
          신청을 수락합니다. 단, “회원”이 탈퇴하기 전에 확인 또는 이행하여야 할 사항이 있다면 “회사”는 “회원”에게 이를
          고지하고 그 확인 또는 이행을 요구할 수 있습니다.
        </li>
        <li>
          이용계약은 “회원”의 탈퇴 의사가 “회사”에 도달할 때 또는 “회원”이 확인 또는 이행하여야 할 사항이 있는 경우 그
          확인 또는 이행의 의무가 완료된 때에 종료됩니다.
        </li>
        <li>
          “회원”이 탈퇴할 때 소진되지 않은 포인트 및 혜택은 “회원”의 의사에 따라 이에 대한 권리를 포기한 것으로 간주하여
          탈퇴와 동시에 전부 소멸됩니다.
        </li>
      </ul>
      <h2 className="subtitle">제8조 (회원자격의 제한, 정지, 상실 및 이용계약의 해지) </h2>
      <ul className="numberList">
        <li>
          “회원”이 다음 각호의 어느 하나에 해당할 경우 “회사”는 본 조에 따라 해당 “회원”에 대하여 이용계약의
          해지(회원자격의 박탈을 포함함), 회원자격의 제한, 중지, 서비스의 전부 또는 일부의 제공 중단 등(이하 “이용계약의
          해지 등”이라 합니다.)의 조치를 취할 수 있습니다.
          <ul>
            <li> 제 6조(회원가입 및 이용계약의 성립) 제2항 각호의 어느 하나에 해당하는 경우 </li>
            <li>제17조(회원의 의무) 제1항 각호의 어느 하나에 해당하는 경우</li>
            <li>
              “포미포인트 서비스” 이용과 관련하여 대금, 비용의 지급 기타 “회원”이 부담하는 채무를 이행하지 않는 경우
            </li>
            <li>
              다른 사람의 명의 또는 정보를 도용하거나 “포미포인트 서비스” 이용을 방해하거나, 부정한 방법으로 “포미포인트
              서비스”를 비정상적으로 이용하는 등으로 전자상거래 질서를 위협하는 경우
            </li>
            <li>“포미포인트” 이용과 관련하여 법령 또는 이 약관에서 금지하거나 공서양속에 반하는 행위를 하는 경우 </li>
          </ul>
        </li>
        <li>
          “회원”이 제1항 각 호의 어느 하나에 해당할 경우, “회사”는 7일 이상의 기간을 정하여 “회원”에게 그 시정을
          요구(최고)할 수 있습니다. “회원”이 위 기한 내에 이를 시정하거나 합리적인 소명을 하지 않을 경우, “회사”는 해당
          “회원”에 대한 통지로써 “이용계약의 해지 등”의 조치를 취할 수 있습니다. 단, “회원”이 제2항에 따른 “회사”의 시정
          요구(최고)에도 불구하고 동일한 행위를 반복하거나 관련 법령 또는 이 약관을 중대하게 위반한 경우에는, “회사”는
          별도의 시정 요구(최고) 없이도 (이미 최고를 한 경우에는 그 최고 기간 중이라도) 해당 회원에 대한 통지로써 즉시
          “이용계약의 해지 등”의 조치를 취할 수 있습니다.
        </li>
        <li>
          “회사”가 제2항에 따라 “회원”과의 이용계약을 해지할 경우, “회원”이 “포미포인트 서비스”의 이용 과정에서 “회사”
          또는 “제휴사”로부터 제공받거나 획득한 포인트 및 혜택은 모두 소멸되며, “회원”은 이에 대하여 “회사”를 상대로
          별도의 배상이나 보상을 요구할 수 없습니다.
        </li>
        <li>
          “회사”가 제2항에 따라 “회원”과의 이용계약을 해지할 경우, “회사”가 보유 중인 “회원”의 개인정보는 관련 법령 및
          “회사”의 개인정보처리방침에 따라 보유하는 경우를 제외하고는 모두 소멸됩니다.
        </li>
      </ul>
      <h2 className="subtitle">제9조 (회원에 대한 통지) </h2>
      <ul className="numberList">
        <li>
          “회사”가 “회원”에게 통지를 하려는 경우, 이 약관에서 달리 정하지 않는 한 “회원”이 지정한 전화 및 전자우편 주소,
          휴대전화 문자메시지, “회원”의 연락처와 연동된 SNS서비스, 어플리케이션 알림(Push) 등으로 할 수 있습니다. 이
          경우 “회원”의 통신환경 또는 통신요금구조 등에 따라 “회원”이 데이터요금 등을 부담할 수 있습니다.
        </li>
        <li>
          “회사”가 불특정다수 “회원”에게 통지를 하려는 경우, 7일 이상 “모바일 어플리케이션”의 ‘공지사항’ 게시판에
          게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는
          사항은 제1항에 따라 통지합니다.
        </li>
      </ul>
      <h2 className="subtitle">제10조 (포인트 적립 및 이용) </h2>
      <ul className="numberList">
        <li>
          “제휴사” 운영 매장에서 상품 등을 구입하고 그에 따른 대금을 결제한 “회원”은 “회사”와 “제휴사”가 약정한 바에
          따라 “포인트”를 제공받습니다. 단, 결제금액에 따른 포인트의 적립률, 회원에 대한 포인트 적립과 복구, 사용정책,
          유효기간 등 세부 사항은 “제휴사”가 정하며, “제휴사”의 “포인트” 관련 정책에 대해서는 “제휴사”가 책임을 지고
          “회사”는 “회원”에 대하여 어떠한 책임을 지지 않습니다.
        </li>
        <li>
          “회원”이 상품 등을 구매 시 “포인트”를 결제 수단으로 사용하는 경우 별도의 포인트 적립률이 적용되거나 포인트가
          적립되지 않을 수 있으며, 이에 대하여 “제휴사”는 “회원”의 구매 또는 결제 시 이러한 사항에 대하여 안내하여야
          합니다.
        </li>
        <li>
          “회원”은 “제휴사”가 정한 기준에 따라 “포인트”를 합산하여 적립 및 사용할 수 있으며, 적립한 포인트는 “제휴사”가
          정한 범위 내의 상품 등으로 교환하거나 구매대금의 일부로 사용할 수 있습니다. 단, “제휴사”는 매장 별로 혜택
          사용을 위한 조건(최소 사용금액 설정, 다른 포인트 또는 혜택과의 중복사용 불가 등)을 부가할 수 있습니다.
        </li>
        <li>“회원”은 본인이 소유한 “포인트”를 타인에게 양도, 대여 또는 담보의 목적으로 이용할 수 없습니다. </li>
        <li>
          “회원”은 다른 회원이 적립한 “포인트”를 부정하게 이용하여서는 아니 되며, “회사”는 포인트에 대한 적법한 권리가
          있는 “회원”으로부터 부정이용 신고 등을 접수한 경우에는 즉시 이를 해결하기 위한 조치를 취하기로 합니다.
        </li>
        <li>“회원”은 적립된 “포인트”를 금전 등 다른 지급수단의 형태로 교환 또는 환불해 줄 것을 요구할 수 없습니다. </li>
        <li>
          “포인트” 적립률 및 기준은 “제휴사”의 방침에 따라 임의로 변경될 수 있으며, “회사”는 “회원”에게 “제휴사”의
          “포인트” 적립률 및 기준 변경에 관하여 어떠한 책임을 지지 아니합니다.
        </li>
        <li>
          “회사”는 “회원”이 “사이트”에서 “포인트”를 사용하는 경우 휴대폰인증 등 회사가 정한 절차를 거치도록 할 수
          있습니다.
        </li>
        <li>
          “회원”이 상품 등의 구매취소, 반품, 기타의 사유에 의해 환원되는 “포인트”의 유효기간이 이미 만료되었을 경우,
          환원되는 “포인트”는 즉시 소멸됩니다.
        </li>
        <li> 회원정보의 변경으로 인한 포인트 이전 등의 처리는 “회사”의 내부 방침에 따릅니다. </li>
        <li>
          기타 이 약관 및 사이트에서 이용안내에 규정되지 않은 사항에 대해서는 소비자 피해보상규정에서 정한 바에
          따릅니다.
        </li>
      </ul>
      <h2 className="subtitle"> 제11조 (포인트 정정 및 취소) </h2>
      <ul className="numberList">
        <li>
          적립된 “포인트”에 오류가 있을 경우 “회원”은 오류발생 시점부터 7일 이내 “회사”나 “제휴사”에 정정 신청을 할 수
          있으며, “회사”나 “제휴사”는 “회원”의 정정 신청일로부터 최대 30일 이내에 조치를 취하기로 합니다. 단, “회원”은
          필요한 경우 포인트 적립 오류를 증명할 수 있는 객관적 자료(영수증 등)를 제시하여야 합니다.
        </li>
        <li>
          “회원”이 상품 등의 구매취소, 반품, 기타의 사유에 의해 “포인트”의 적립이 취소되는 경우 취소분만큼 기 지급된
          포인트는 자동으로 차감됩니다.
        </li>
      </ul>
      <h2 className="subtitle">제12조 (포인트 유효기간 및 소멸) </h2>
      <ul className="numberList">
        <li>“회원”이 회원탈퇴를 하는 경우 및 “포인트”의 유효기간이 만료되는 경우 회원의 포인트는 소멸됩니다. </li>
        <li>
          “포인트”의 유효기간은 “제휴사”의 각 정책에 따르며, “제휴사”는 매장 별 포인트의 유효기간을 “회원”의 상품 등의
          구매 또는 결제 시 안내하여야 하며, 위 유효기간 내에 사용되지 않은 “포인트”는 유효기간 만료일 당일에 자동
          소멸됩니다.
        </li>
        <li>
          회원탈퇴 및 유효기간 만료로 인하여 소멸된 “포인트”는 다음 각 호에서 정한 바에 따라 처리됩니다.
          <ul>
            <li>
              회원탈퇴로 인한 포인트 소멸 : 회원탈퇴 처리 완료일까지 적립된 포인트는 회원탈퇴 이후 사용이 불가합니다.
            </li>
            <li>유효기간 만료로 인한 포인트 소멸 : 유효기간이 만료된 포인트는 어떠한 경우에도 복원되지 않습니다. </li>
          </ul>
        </li>
        <li>
          “회사”는 유효기간 만료에 따른 소멸예정 포인트, 소멸시기 등 포인트 소멸과 관련된 내용을 “회원”이 “모바일
          어플리케이션”에서 확인할 수 있도록 조치합니다
        </li>
      </ul>
      <h2 className="subtitle">제13조 (모바일 어플리케이션 이용) </h2>
      <p>
        “회원”은 “모바일 어플리케이션”을 이용하여 “제휴사”가 제공하는 매장 별 포인트를 적립 받을 수 있으며, 적립 받은
        포인트를 “매장”에 상품 등의 구매 시 사용할 수 있습니다.
      </p>
      <h2 className="subtitle">제14조 (개인정보의 보호) </h2>

      <ul className="numberList">
        <li>
          “회사”는 “회원”의 개인정보 수집 시 “포미포인트 서비스”의 제공을 위하여 필요한 범위에서 최소한의 개인정보를
          수집합니다.
        </li>
        <li>
          “회사” “회원”의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및
          『개인정보보호법』 등 관련 법령에서 정하는 바를 준수합니다.
        </li>
        <li>
          단, “포미포인트” 이외의 링크된 기타 사이트에서는 “회사”의 개인정보처리방침이 적용되지 않습니다. “회원”은
          이러한 개인정보의 취급 및 처리 방침을 확인할 책임이 있으며, “회사”는 “회원”의 과실에 대하여 책임을 지지
          않습니다.
        </li>
        <li>
          “회사”는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게
          제공하는 경우에는 사전에 정보주체에게 그 목적을 고지하고 동의를 받기로 합니다. 다만, 관련 법령에 달리 정함이
          있는 경우에는 예외로 합니다.
        </li>
        <li>
          “회사”가 정보주체의 동의를 받아야 하는 경우에는 개인정보 관리책임자의 신원(소속, 성명 및 전화번호, 기타
          연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의
          내용) 등 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 관련 법령에서 규정한 사항을 미리 명시하거나
          고지해야 하며 정보주체는 언제든지 이 동의를 철회할 수 있습니다.
        </li>
        <li>
          정보주체는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며, “회사”는
          이에 대해 지체 없이 필요한 조치를 취하여야 합니다. 정보주체의 오류 정정 요구가 있는 경우, “회사”는 그 오류를
          정정할 때까지 해당 개인정보를 이용하지 않습니다.
        </li>
        <li>
          “회사”는 개인정보의 보호를 위하여 정보주체의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 개인정보의
          분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 정보주체의 손해에 대하여 “회사”는 배상할 책임을
          부담합니다. 단, “회사”에게 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
        </li>
        <li>
          “회사” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 처리목적을 달성하거나 보관기간이 도래한 경우 해당
          개인정보를 지체 없이 파기합니다.
        </li>
        <li>
          “회사”는 개인정보의 수집·이용·처리에 관한 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이
          아닌 개인정보의 수집·이용·처리에 관한 정보주체의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나
          거절하지 않습니다.
        </li>
        <li>
          “회사”는 다음과 같은 경우에 법이 허용하는 범위 내에서 정보주체의 개인정보를 제3자에게 제공할 수 있습니다.
          <ul>
            <li>수사기관이나 기타 정부기관으로부터 적법한 절차에 따른 정보제공을 요청받은 경우</li>
            <li>정보주체가 관련 법령을 위반하는 등의 부정행위를 확인하기 위해 필요한 경우</li>

            <li>서비스 제공 또는 관리에 필요한 최소한의 개인정보(성명, 생년월일, 전화번호)를 제공하여야 하는 경우</li>

            <li>서비스 이용이 종료된 이후에도 반품, 교환, 환불, 취소 등을 위하여 필요한 경우</li>
            <li>기타 관련 법령에 의해 요구되는 경우 </li>
          </ul>
        </li>
        <li>기타 본 조에서 정하지 않은 사항은 회사의 개인정보 처리방침에 따릅니다. </li>
      </ul>
      <h2 className="subtitle"> 제15조 (정보의 제공 및 광고의 게재) </h2>
      <ul className="numberList">
        <li>
          “회사”는 “포미포인트 서비스”의 이용에 관한 정보를 공지사항이나 제9조(회원에 대한 통지)의 방법으로 “회원”에게
          제공할 수 있습니다.
        </li>
        <li>
          “회원”은 관련 법령에 따른 경우를 제외하고는 언제든지 전항의 정보 제공에 대해서 수신을 거절을 할 수 있으며, 이
          경우 “회사”는 즉시 정보 제공 행위를 중단합니다.
        </li>
        <li>
          다만 “회원”이 전항에 의해 정보 수신을 거절한 경우, “회사”는 “모바일 어플리케이션”의 ‘공지사항’ 등 화면에
          공지하는 방식으로 정보의 제공을 갈음할 수 있으며, “회원”이 공지사항을 확인하지 아니하여 발생하는 손해에 대하여
          “회사”는 책임을 지지 않습니다.
        </li>
        <li>
          “회사”는 “회원”이 마케팅 정보 수신동의를 한 경우, 다양한 광고(마케팅) 정보를 공지사항이나 제9조(회원에 대한
          통지)의 방법으로 제공할 수 있습니다. 이 경우 회원의 통신환경 또는 요금구조 등에 따라 회원이 데이터요금 등을
          부담할 수 있습니다.
        </li>
        <li>
          “회원”은 언제든지 전항의 광고(마케팅) 정보 수신을 거절을 할 수 있으며, 이 경우 “회사”는 즉시 광고(마케팅) 정보
          제공 행위를 중단합니다.
        </li>
      </ul>
      <h2 className="subtitle">제16조 (회사의 의무) </h2>
      <ul className="numberList">
        <li>
          “회사”는 관련 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라
          지속적이고 안정적으로 “포미포인트”를 운영하는데 최선을 다하여야 합니다.
        </li>
        <li>
          “회사”는 “회원”이 안전하게 ”포미포인트 서비스”를 이용할 수 있도록 “회원”의 개인정보(신용정보 포함)보호를 위한
          보안 시스템을 갖추어야 합니다.
        </li>
      </ul>
      <h2 className="subtitle"> 제17조 (회원의 의무) </h2>
      <ul className="numberList">
        <li>
          “회원”은 다음 각 호의 행위를 하여서는 안됩니다.
          <ul>
            <li> 회원가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위 </li>
            <li>타인의 명의나 정보(개인정보, 카드정보, 계좌정보 등을 포함하며 이에 한정되지 않음)를 도용하는 행위</li>
            <li> “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신 또는 게시하는 행위 </li>
            <li> “회사” 기타 제3자의 저작권 등 지식재산권을 침해하는 행위 </li>
            <li> “회사” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </li>
            <li> “포미포인트 서비스”의 이용 과정에서 허위의 정보를 제공하는 행위 </li>
            <li> 제3자의 ID를 이용하거나 자신의 ID를 제3자에게 제공하는 행위 </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 ”모바일 어플리케이션”에 게시하는 행위
            </li>
            <li>
              ”재화 등”에 대하여 환불을 신청하였거나 이미 환불을 받은 뒤, “제휴사”에게 “재화 등”의 제공을 요구하는 행위
            </li>
            <li> ”재화 등”의 사용을 완료한 뒤, “회사”에는 사용 전 “재화 등”이라고 진술하여 환불을 요구하는 행위 </li>
            <li> 구매를 가장하여 비정상적이거나 부정한 행위를 하는 경우 </li>
            <li> 진정한 구매 의사 없이 “포인트”을 적용하여 “재화 등”을 구매하는 행위 </li>
            <li> “회사”의 허락 없이 “포미포인트”를 이용하여 영업/광고 활동 등을 하는 행위 </li>
            <li>1타인의 신용카드, 은행 계좌, 휴대폰 등을 무단으로 이용 또는 도용하여 재화 등을 구매하는 행위 </li>
            <li> 비정상적인 방법으로 “포인트”를 현금으로 환급받는 행위 </li>
            <li> “회사” 또는 “제휴사”의 영업을 방해하는 행위 </li>
            <li>
              “회사” 또는 “제휴사”의 상담원 기타 임직원에 대한 폭행, 협박, 욕설, 성희롱 등 산업안전보건법 기타 관련
              법령에 위배되는 행위
            </li>
            <li>
              여신전문금융업법 등 관련 법령을 위한하여 비정상적인 결제를 하거나 “포미포인트 서비스” 기타 “회사”의
              시스템을 비정상적으로 이용하는 행위
            </li>
            <li>
              자신 또는 제3자의 ID를 이용하여 직접 사용을 위한 의사 없이(직접 사용의 증거를 제시하지 못한 경우를 포함함)
              재판매 목적 또는 환불 정책, 고객 보상 정책 등을 이용한 부당한 이익 취득을 위한 목적으로 “사이트”에서 자신
              또는 제3자의 상품을 반복적으로 또는 대량으로 구매하거나 구매한 것처럼 보이도록 하는 행위, 그러한 행위를
              교사 또는 방조하는 행위, 기타 단독으로 또는 제3자와 공모하여 “서비스”를 부정하게 또는 비정상적으로
              이용하여 자신 또는 제3자가 부당한 이득을 취하게 하고 “회사” 또는 타인에게 손해 또는 손실을 입히는 행위
            </li>
            <li>
              기타 본 항 각 호의 어느 하나 또는 그 이상에 해당한다는 합리적인 의심(정당한 권리자로부터 신고가 접수된
              경우를 포함함)이 있어 “회사”가 “회원”에게 그에 대한 소명 및 객관적인 입증자료를 요청하였음에도 불구하고,
              “회원”이 그에 대한 소명 또는 객관적인 입증자료를 제출하지 못하거나 허위의 소명 또는 자료를 제출하는 행위
            </li>
          </ul>
        </li>
        <li>
          “회원”은 “회원”의 정보가 변경된 경우 이를 즉시 “회사”에 알려야 하며, 이를 지체하여 발생하는 “회원”의 불이익에
          대하여 “회사”는 책임을 지지 않습니다.
        </li>
        <li>“회원”은 관계 법령 및 이 약관 기타 “회사”의 규정을 준수하여야 합니다. </li>
        <li> “회원”은 “포미포인트”의 공지사항 등 “회사”가 정한 규정을 수시로 확인하여야 합니다. </li>
      </ul>
      <h2 className="subtitle">제18조 (지식재산권 등) </h2>
      <ul className="numberList">
        <li>
          “회사”가 ”포미포인트 서비스”에 등록하거나 게시한 저작물 기타 자료 또는 정보에 대한 소유권 및 저작권 기타
          지식재산권은 “회사”에게 귀속됩니다.
        </li>
        <li>
          “회원”은 회사가 소유권이나 저작권 기타 지식재산권을 가지는 저작물 기타 자료 또는 정보를 “회사”의 사전 승낙
          없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는
          안됩니다.
        </li>
        <li>
          “회원”이 “포미포인트 서비스”에 등록한 각종 “게시물” 기타 저작물의 저작권은 “회원”에게 귀속되며, “회원”은
          “회사”에 대하여 이에 대한 무상의 비독점적인 사용(복제, 배포, 전시, 재사용을 포함함) 권한을 부여합니다.
        </li>
        <li>
          “회사”는 서비스와 관련하여 “회원”에게 회사가 정한 이용조건에 따라 계정, 콘텐츠 등을 이용할 수 있는
          이용권한만을 부여하며, “회원”은 이를 양도, 판매, 담보 제공하는 등으로 처분할 수 없습니다.
        </li>
      </ul>
      <h2 className="subtitle"> 제19조 (손해배상) </h2>
      <p>
        “회사” 또는 “회원”은 관련 법령 및 이 약관을 위반함으로 인하여 상대방에게 발생한 손해를 배상하여야 하고, 이 때
        귀책당사자는 고의 또는 과실이 없었음을 입증하지 못하는 한 책임을 면할 수 없습니다.
      </p>
      <h2 className="subtitle"> 제20조 (분쟁해결) </h2>

      <ul className="numberList">
        <li>
          “회사”는 “회원”이 제출하는 불만사항 및 의견을 신속하게 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
          “회원”에게 그 사유와 처리일정을 통보할 수 있습니다.
        </li>
        <li>
          “회사”와 “회원” 간에 발생한 전자상거래 분쟁과 관련하여 “회원”의 피해구제신청이 있는 경우 공정거래위원회 또는
          시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
        </li>
      </ul>
      <h2 className="subtitle">제21조 (책임제한) </h2>
      <ul className="numberList">
        <li>
          “회사”는 천재지변, 화재, 홍수, 사고 또는 정부기관의 조치 기타 사회통념상 부득이한 경우 등 합리적 통제를 위한
          최선의 노력에도 불구하고 이 약관 상의 의무를 이행할 수 없는 사유가 발생한 경우, “포미포인트 서비스”의 제공
          기타 이 약관에 따른 책임을 지지 않습니다.
        </li>
        <li>
          “회사”는 기간통신사업자 등 전기통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여
          “회원”에게 손해에 대하여 책임이 면제됩니다.
        </li>
        <li> “회사”는 “회원”의 귀책사유로 인한 “포미포인트 서비스”의 이용 장애에 대하여 책임을 지지 않습니다. </li>
        <li>
          “회사”는 사전에 “회원”에게 공지한 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나
          장애가 발생한 경우에 대하여 책임이 면제됩니다.
        </li>
        <li>
          “회사”는 “제휴사”와 “회원” 간의 “재화 등”의 거래를 중개하는 서비스를 제공할 뿐, “제휴사” 또는 “회원”이 게재한
          정보, 자료, 사실의 신뢰도 및 정확성에 대하여 책임을 지지 않습니다.
        </li>
        <li>
          “재화 등”에 대한 정보 및 배송, 품질의 보증, 하자 등에 대한 모든 책임은 “제휴사”에게 있고, “회사”는 이에 대하여
          별도의 책임을 지지 않습니다.
        </li>
        <li>
          “회사”는 “회원”이 “포미포인트 서비스”를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지
          않습니다.
        </li>
        <li>
          “회사”는 “회원”과 또 다른 “회원” 간 또는 “회원”과 제3자간에 “포미포인트 서비스”를 매개로 한 거래 또는 분쟁에
          대하여 책임이 면제 됩니다.
        </li>
      </ul>
      <h2 className="subtitle">제22조 (준거법 및 관할법원) </h2>
      <ul className="numberList">
        <li>
          이 약관의 해석에 관한 이견 기타 이 약관에 관한 “회사”와 “회원” 간의 분쟁 등에는 대한민국 법률을 적용합니다.
        </li>
        <li>이 약관에 관하여 “회사”와 “회원” 간에 제기된 소송은 서울중앙지방법원을 제1심 관할 법원으로 합니다. </li>
      </ul>
    </div>
  );
};

export default PrivacyTerms;
